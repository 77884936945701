<template>
  <div class="map-component">
    <img src="@/assets/img/toppng@3x.png" alt="" class="top-img" />
    <van-search
      v-model="searchValue"
      shape="round"
      placeholder="输入文物名称进行查找"
      @input="getSearchNameList"
    />
    <div class="search-list" v-if="searchNameListActive">
      <div
        v-for="(item, index) in searchNameList"
        :key="index"
        @click="
          () => {
            searchNameListActive = false;
            getPanTo(item);
          }
        "
      >
        {{ item }}
      </div>
    </div>
    <div id="Map" ref="map"></div>
    <div class="pop-mc pop2" @click="setActive" v-if="show">
      <van-popup
        v-model="show"
        closeable
        position="bottom"
        :style="{ height: '65%' }"
        :overlay="false"
        :round="true"
      >
        <div class="detail-content" ref="detailContent" v-if="detailContent.id">
          <div>
            <div
              class="road"
              @click="goToRoad(detailContent.longitude, detailContent.latitude)"
            >
              <img
                class="btn-map"
                src="@/assets/img/button_map@2x.png"
                alt=""
              />
              <div>路线</div>
            </div>

            <div class="detail-title">{{ detailContent.name }}</div>
            <div class="detail-address">{{ detailContent.address }}</div>
            <div class="detail-level">
              <span>{{ detailContent.level }} </span
              ><span v-if="detailContent.typeName">｜</span
              ><span> {{ detailContent.typeName }} | </span>
              <span v-if="detailContent.town">{{ detailContent.town }}</span>
            </div>
            <div class="detail-imgs" v-if="detailContent.images.length > 0">
              <img
                v-for="(item, index) in detailContent.images.slice(0, 3)"
                :key="index"
                @click="
                  previewImg(`http://smartsite.api.zhiqiankeji.com/${item}`)
                "
                :src="'http://smartsite.api.zhiqiankeji.com/' + item"
              />
            </div>
            <div class="detail-tab">
              <van-tabs
                v-model="active"
                animated
                color="#CC9C4F"
                :line-width="18"
                swipeable
                title-active-color="#CC9C4F"
                sticky
                offset-top="35vh"
              >
                <van-tab title="概览">
                  <div class="fenge"></div>
                  <div class="overview">
                    <div class="overview-desc">
                      {{ detailContent.description }}
                    </div>
                  </div>
                </van-tab>
                <van-tab title="相册">
                  <div class="fenge"></div>
                  <div class="container-water-fall">
                    <waterfall
                      :col="col"
                      :width="itemWidth"
                      :gutterWidth="gutterWidth"
                      :data="detailContent.images"
                    >
                      <template>
                        <div
                          class="cell-item"
                          v-for="(item, index) in detailContent.images"
                          :key="index"
                        >
                          <img
                            v-if="item"
                            :src="
                              'http://smartsite.api.zhiqiankeji.com/' + item
                            "
                            alt="加载错误"
                            @click="
                              previewImg(
                                `http://smartsite.api.zhiqiankeji.com/${item}`
                              )
                            "
                          />
                        </div>
                      </template>
                    </waterfall>
                  </div>
                </van-tab>
                <van-tab title="附近">
                  <div class="fenge"></div>

                  <div class="around" ref="element">
                    <waterfall
                      :col="col"
                      :width="itemWidth"
                      :gutterWidth="gutterWidth"
                      :data="detailAroundData"
                    >
                      <template>
                        <div
                          class="around-item"
                          v-for="(item, index) in detailAroundData"
                          :key="index"
                        >
                          <img
                            :src="
                              item.photos.length
                                ? item.photos[0].url
                                : require('../../assets/img/zhanweitu.png')
                            "
                            alt=""
                          />
                          <div class="juli">
                            <img src="../../assets/img/add.png" alt="" />
                            <span
                              >{{ (item.distance * 0.001).toFixed(2) }}km</span
                            >
                            <span class="mlr">|</span>
                            <span>{{
                              item.type.split(";")[
                                item.type.split(";").length - 1
                              ]
                            }}</span>
                          </div>
                          <div class="name">{{ item.name }}</div>
                          <div class="address" v-if="item.address.length">
                            {{ item.address }}
                          </div>
                        </div>
                      </template>
                    </waterfall>
                  </div>
                </van-tab>
              </van-tabs>
            </div>
          </div>
        </div>
      </van-popup>
    </div>

    <div class="actions">
      <div @click="listShow">
        <div><img src="@/assets/img/map_list.png" alt="" /></div>
        <div>列表</div>
      </div>

      <div class="fenge-line"></div>
      <div @click="filter">
        <div><img src="@/assets/img/map_choose.png" alt="" /></div>
        <div>筛选</div>
      </div>
    </div>

    <!-- 筛选 -->
    <div class="choose">
      <van-popup
        v-model="showChoose"
        closeable
        position="bottom"
        :style="{
          width: '96%',
          height: '41%',
          left: '50%',
          marginLeft: '-48%',
        }"
        :overlay="false"
        :round="true"
      >
        <h2>筛选</h2>
        <div class="filter-content">
          <div v-for="(item, index) in chooseList" :key="index">
            <div class="filter-type">{{ item.name }}</div>
            <div class="filter-click">
              <span
                v-for="(e, i) in item.value"
                :class="e.active ? 'active' : ''"
                :key="i"
                @click="changeFilter(index, i)"
              >
                {{ e.label }}
              </span>
            </div>
          </div>
        </div>

        <div class="filter-buttons">
          <van-button round plain block :style="{ width: '48%' }" @click="reset"
            >重置</van-button
          >
          <van-button
            color="#CC9C4F"
            round
            block
            :style="{ width: '48%' }"
            @click="confim"
            >确定</van-button
          >
        </div>
      </van-popup>
    </div>
    <div
      class="position"
      @click="getCurrentPosition"
      ref="pos"
      style="width: 40px; height: 40px"
    >
      <img
        style="width: 20px; height: 20px"
        src="@/assets/img/position.jpg"
        alt=""
      />
    </div>

    <!-- 列表 -->
    <div class="pop-mc" @click="setListActive" v-if="listActive">
      <van-popup
        v-model="listActive"
        closeable
        position="bottom"
        :style="{ height: '65%', overflow: 'hidden' }"
        :overlay="false"
        :round="true"
      >
        <div class="l-bg">
          <div class="l-c-bg">
            <div class="detail-title">列表</div>
            <div class="detail-address">文物数量：{{ dataList.length }}</div>
          </div>
          <div class="list-c">
            <van-collapse v-model="activeName" accordion>
              <van-collapse-item
                :title="item.town"
                :name="item.town"
                v-for="(item, index) in dataChange"
                :key="index"
              >
                <template #title>
                  <div
                    class="list-title"
                    :class="activeName == item.town ? 'active' : ''"
                  >
                    <span> {{ item.town }}</span>

                    <span>{{ item.data.length }}</span>
                  </div>
                </template>
                <div
                  class="town-item"
                  v-for="(a, b) in item.data"
                  :key="b"
                  @click="showDetail(a.id)"
                >
                  {{ a.name }}
                </div>
              </van-collapse-item>
            </van-collapse>
          </div>
        </div>
      </van-popup>
    </div>
  </div>
</template>

<script>
import {
  getBasicInfoList,
  getBasicDetailInfo,
  getTypeInfoList,
  getNameInfoList,
} from "@/api/home";
let chooseList = [
  {
    name: "文物等级",
    value: [
      {
        id: 100,
        label: "国家级文物",
        active: true,
      },
      {
        id: 101,

        label: "市级文物",
        active: true,
      },
      {
        id: 102,
        label: "区级文物",
        active: true,
      },
    ],
  },
  {
    name: "文物类别",
    value: [
      {
        id: 1,
        label: "古建筑",
        active: true,
      },
      {
        id: 2,
        label: "古遗址",
        active: true,
      },
      {
        id: 3,
        label: "近现代建筑",
        active: true,
      },
    ],
  },
];
export default {
  data() {
    return {
      cluster: null,
      pageNum: 1,
      pageSize: 9,
      detailAroundData: [],
      searchNameListActive: false,
      searchNameList: [], //联想搜索列表展示
      searchValue: "",
      map: null,
      markerLayer: null,
      list: [],
      show: false,
      showChoose: false,
      active: 0,
      cortolAnmete: false,
      chooseList: chooseList,
      itemWidth: 338 * 0.5 * (document.documentElement.clientWidth / 375),
      dataImgList: [],
      dataList: [],
      col: 2,
      page: 1,
      detailContent: {},
      myPosition: null,
      dataChange: {},
      listActive: false,
      activeName: "",
    };
  },
  computed: {
    // 瀑布流计算照片宽
    // itemWidth() {
    //   return 338 * 0.5 * (document.documentElement.clientWidth / 375); //rem布局 计算列宽度 //可以根据自己的rem设置修改这里的 338 值
    // },
    // 瀑布流计算两照片之间间距
    gutterWidth() {
      return 14 * (document.documentElement.clientWidth / 375); //rem布局 计算x轴方向margin(y轴方向的margin自定义在css中即可) //可以根据自己的rem设置修改这里的 14 值
    },
  },
  mounted() {
    this.initMap2();
    this.getList();
  },
  methods: {
    showDetail(id) {
      // this.listActive = false
      this.show = true;
      this.getDetail(id);
    },
    listShow() {
      this.dataChange = this.changeListData(this.dataList);
      this.listActive = true;
    },
    changeListData(arr) {
      var townList = [],
        newArr = [];
      arr.forEach((item) => {
        var flag = -1;
        townList.forEach((a, b) => {
          if (a == item.town) {
            flag = b;
          }
        });
        if (flag == -1) {
          newArr.push({
            town: item.town,
            data: [item],
          });
          townList.push(item.town);
        } else {
          newArr[flag].data.push(item);
        }
      });
      return newArr;
    },
    setActive(e) {
      if (e.target.className == "pop-mc pop2") {
        this.show = false;
      }
    },

    setListActive(e) {
      if (e.target.className == "pop-mc") {
        this.listActive = false;
      }
    },

    scroll() {
      // let height = this.$refs.element.offsetHeight //100
    },

    goToRoad(lng, lat) {
      this.$router.push({
        path: `/mapDet`,
        query: {
          lng,
          lat,
        },
      });
    },
    initMap2() {
      this.map = new AMap.Map("Map", {
        zoom: 11, //级别
        center: [121.510917, 30.967583], //中心点坐标
      });
    },
    getAround(lng, lat) {
      this.$axios
        .get(
          `https://restapi.amap.com/v3/place/around?key=bda05172df6fdd9934d2886d226fa5c7&location=${lng},${lat}&keywords=&types=商场|风景名胜&radius=10000&offset=20&page=1&extensions=all`
        )
        .then((res) => {
          this.detailAroundData = res.data.pois;
        });
    },
    //获取联想搜索列表
    getSearchNameList() {
      if (this.searchValue == "") return;
      getNameInfoList(this.searchValue).then((res) => {
        if (res.data.success) {
          if (res.data.result.length > 0) {
            this.searchNameListActive = true;
          }
          this.searchNameList = res.data.result;
        }
      });
    },
    getType() {
      //获取文物类别接口调用
      getTypeInfoList().then((res) => {
        let typeList = [];
        if (res.data.success) {
          res.data.result.forEach((item) => {
            typeList.push({
              id: item.id,
              label: item.name,
              active: true,
            });
          });
          this.chooseList[1].value = typeList;
        }
      });
    },
    getList() {
      //获取文物列表接口调用
      getBasicInfoList({ pageNum: 1, pageSize: 300, name }).then((res) => {
        if (res.data.success) {
          this.dataList = res.data.result.list;
          //更改瀑布流相册
          this.dataImgList = res.data.result.list.images;
          this.map.panTo([
            this.dataList[0].longitude,
            this.dataList[0].latitude,
          ]);
          //地图撒点
          this.spot2();
        }
      });
    },
    getPanTo(name) {
      getBasicInfoList({ pageNum: 1, pageSize: 300, name }).then((res) => {
        if (res.data.success) {
          this.map.panTo(
            new window.TMap.LatLng(
              res.data.result.list[0].latitude,
              res.data.result.list[0].longitude
            )
          );
          this.show = true;
          this.getDetail(res.data.result.list[0].id);
        }
      });
    },
    //初始化地图
    initMap() {
      this.map = new window.TMap.Map("Map", {
        zoom: 13, //设置地图缩放级别
        center: new window.TMap.LatLng(30.894155, 121.587721), //设置地图中心点坐标
        mapStyleId: "style 1 白浅", //个性化样式
      });
      this.getCurrentPosition();
      this.spot();
      this.map.setCenter(
        new window.TMap.LatLng(this.myPosition.lat, this.myPosition.lng)
      );
    },
    // 获取当前自身位置
    getCurrentPosition() {
      var geolocation = new qq.maps.Geolocation(
        "XJFBZ-Q3IKX-5ZS43-TYAJ4-Q3C7E-2XBS4",
        "myapp"
      );
      geolocation.getLocation(showPosition, showErr);
      function showPosition(position) {
        this.myPosition = position;
      }
      function showErr() {
        alert("定位失败");
      }
    },
    spot2() {
      if (this.cluster) {
        this.cluster.setMap(null);
      }
      var that = this;
      this.list = [];
      this.dataList.forEach((item) => {
        if (item.longitude && item.latitude) {
          this.list.push({
            lnglat: [item.longitude, item.latitude],
            data: item,
          });
        }
      });
      this.cluster = new AMap.MarkerCluster(this.map, this.list, {
        gridSize: 60, // 聚合网格像素大小
        renderClusterMarker: this._renderClusterMarker, // 自定义聚合点样式
        renderMarker: this._renderMarker, // 自定义非聚合点样式
      });
      this.cluster.on("click", function (e) {
        if (e.clusterData.length > 1) {
          var curZoom = that.map.getZoom();
          if (curZoom < 20) {
            //判断现在地图层级
            curZoom += 1;
          }
          that.map.setZoomAndCenter(curZoom, e.lnglat); //设置层级和中心点
        } else {
          that.getDetail(e.clusterData[0].data.id);
          that.getAround(e.lnglat.lng, e.lnglat.lat);
          that.show = true;
          that.active = 0;
        }
      });
    },
    // 地图撒点
    spot() {
      console.log(this.dataList);
      if (this.markerLayer) {
        this.markerLayer.setGeometries([]);
      }
      return;
      // this.list = []
      // this.dataList.forEach(item => {
      //   let obj = {
      //     id: item.id, //点标记唯一标识，后续如果有删除、修改位置等操作，都需要此id
      //     styleId: 'icon', //指定样式id
      //     position: new window.TMap.LatLng(item.latitude, item.longitude), //点标记坐标位置
      //     data: item
      //   }
      //   this.list.push(obj)
      // })
      // this.markerLayer = new window.TMap.MultiMarker({
      //   map: this.map,
      //   styles: {
      //     icon: new window.TMap.MarkerStyle({
      //       width: 30, // 点标记样式宽度（像素）
      //       height: 30, // 点标记样式高度（像素）
      //       src: require('@/assets/img/map_icon.jpg'), //图片路径
      //       //焦点在图片中的像素位置，一般大头针类似形式的图片以针尖位置做为焦点，圆形点以圆心位置为焦点
      //       anchor: { x: 16, y: 32 }
      //     })
      //   },
      //   geometries: this.list
      // })
      // this.markerLayer.on('click', evt => {
      //   this.getDetail(evt.geometry.id)

      //   this.show = true
      //   this.cortolAnmete = true
      //   this.showChoose = false
      //   this.$nextTick(() => {
      //     this.itemWidth =
      //       338 *
      //       0.5 *
      //       (this.$refs.detailContent &&
      //         this.$refs.detailContent.clientWidth / 375)
      //   })
      // })
    },
    getDetail(id) {
      //获取文物详细信息
      getBasicDetailInfo({ id }).then((res) => {
        if (res.data.success) {
          this.detailContent = res.data.result;
        }
      });
    },
    // 筛选控制显示
    filter() {
      this.show = false;
      this.showChoose = true;
    },
    changeFilter(index, i) {
      this.chooseList[index].value[i].active =
        !this.chooseList[index].value[i].active;
    },
    // 筛选确认按钮
    confim() {
      this.showChoose = false;
      let levelList = [],
        typeIdList = [];
      //通过active字段判断是否选中
      this.chooseList[0].value.forEach((item) => {
        if (item.active) {
          levelList.push(item.label);
        }
      });
      console.log(this.chooseList);
      this.chooseList[1].value.forEach((item) => {
        if (item.active) {
          typeIdList.push(item.id);
        }
      });
      console.log(levelList, typeIdList);
      getBasicInfoList({
        pageNum: 1,
        pageSize: 300,
        levelList,
        typeIdList,
      }).then((res) => {
        if (res.data.success) {
          this.dataList = res.data.result.list;
          //更改瀑布流相册
          this.dataImgList = res.data.result.list.images;
          this.spot2();
        }
      });
    },
    // 筛选重置按钮
    reset() {
      this.chooseList.forEach((item, index) => {
        item.value.forEach((e, i) => {
          this.chooseList[index].value[i].active = false;
        });
      });
    },
    switchCol(col) {
      this.col = col;
    },
    // 图片点击全屏显示的函数
    previewImg(url) {
      this.$hevueImgPreview({
        url,
        mainBackground: "rgba(0, 0, 0, .5)", // 整体背景颜色
        closeColor: "#fff", //关闭按钮颜色
      });
    },
    _renderMarker(context) {
      var content = `<div>
        <img width="30px" height="30px" src="${require("@/assets/img/map_icon.jpg")}" />
      </div>`;
      var offset = new AMap.Pixel(-9, -9);
      context.marker.setContent(content);
      context.marker.setOffset(offset);
    },

    _renderClusterMarker(context) {
      var count = this.list.length;
      // 聚合中点个数
      var clusterCount = context.count;
      var div = document.createElement("div");
      var bgColor = "0,151,251";
      // if (clusterCount >= 0 && clusterCount < 10) {
      //   bgColor = defaultColor[0]
      // } else if (clusterCount >= 10 && clusterCount < 20) {
      //   bgColor = defaultColor[1]
      // } else if (clusterCount >= 20 && clusterCount < 30) {
      //   bgColor = defaultColor[2]
      // } else if (clusterCount >= 30 && clusterCount < 40) {
      //   bgColor = defaultColor[3]
      // } else if (clusterCount >= 50) {
      //   bgColor = defaultColor[4]
      // }
      div.style.backgroundColor = "rgba(" + bgColor + ",.5)";
      var size = Math.round(25 + Math.pow(clusterCount / count, 1 / 5) * 40);
      div.style.width = div.style.height = size + "px";
      div.style.border = "solid 1px rgba(" + bgColor + ",1)";
      div.style.borderRadius = size / 2 + "px";
      div.innerHTML = context.count;
      div.style.lineHeight = size + "px";
      div.style.color = "#ffffff";
      div.style.fontSize = "12px";
      div.style.textAlign = "center";
      context.marker.setOffset(new AMap.Pixel(-size / 2, -size / 2));
      context.marker.setContent(div);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "./index.scss";
</style>
