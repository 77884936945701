import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import "lib-flexible";
import Vant from "vant";
import "vant/lib/index.css";
import "./assets/css/reset.css";
import { VueJsonp } from "vue-jsonp";
import animate from "animate.css";
import hevueImgPreview from "hevue-img-preview";
import waterfall from "vue-waterfall2";
import axios from 'axios';
Vue.use(waterfall);
Vue.use(hevueImgPreview);
Vue.use(animate);
Vue.use(Vant);
Vue.prototype.$axios = axios

Vue.use(VueJsonp);
Vue.config.productionTip = false;

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
