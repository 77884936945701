import { http } from "@/utils/http.js";

//获取文物列表
export const getBasicInfoList = async (data) => {
  return await http.post(`/basicInfoList`, data);
};
//获取文物详细信息
export const getBasicDetailInfo = async (data) => {
  return await http.post(`/basicDetailInfo`, data);
};
//获取文物类别列表
export const getTypeInfoList = async () => {
  return await http.post(`/typeInfoList`);
};
//获取联想文物名字列表
export const getNameInfoList = async (name) => {
  return await http.post(`/nameInfoList`, { name });
};
