<template>
  <div class="map-detail">
    <div id="Map"></div>
  </div>
</template>

<script>
export default {
  props: ['road1', 'road2'],
  data() {
    return {
      map: null,
      myPosition: null
    }
  },
  mounted() {
    this.getCurrentPosition()
    // this.ab()
  },
  methods: {
    // 获取当前自身位置
    getCurrentPosition() {
      var that = this
      AMap.plugin('AMap.Geolocation', function() {
        var geolocation = new AMap.Geolocation({
          // 是否使用高精度定位，默认：true
          enableHighAccuracy: true,
          // 设置定位超时时间，默认：无穷大
          timeout: 10000,
          // 定位按钮的停靠位置的偏移量，默认：Pixel(10, 20)
          buttonOffset: new AMap.Pixel(10, 20),
          //  定位成功后调整地图视野范围使定位位置及精度范围视野内可见，默认：false
          zoomToAccuracy: true,
          //  定位按钮的排放位置,  RB表示右下
          buttonPosition: 'RB'
        })

        geolocation.getCurrentPosition(function(status, result) {
          if (status == 'complete') {
            onComplete(result)
          } else {
            onError(result)
          }
        })

        function onComplete(data) {
          var arr = [] //用户当前位置
          arr.push(data.position.lng)
          arr.push(data.position.lat)
          that.ab(arr)
        }

        function onError(data) {
          // 定位出错
          alert(JSON.stringify(data), 'err')
        }
      })
    },
    ab(arr) {
      var map = new AMap.Map('Map', {
        center: [116.397559, 39.89621],
        zoom: 14
      })

      // 当前示例的目标是展示如何根据规划结果绘制路线，因此walkOption为空对象
      var walkingOption = {}

      // 步行导航
      var walking = new AMap.Walking(walkingOption)
      var arr2 = [this.$route.query.lng, this.$route.query.lat] //传过来的 文物位置
      //根据起终点坐标规划步行路线
      walking.search(arr, arr2, function(status, result) {
        // result即是对应的步行路线数据信息，相关数据结构文档请参考  https://lbs.amap.com/api/javascript-api/reference/route-search#m_WalkingResult
        if (status === 'complete') {
          if (result.routes && result.routes.length) {
            drawRoute(result.routes[0])
            log.success('绘制步行路线完成')
          }
        } else {
          log.error('步行路线数据查询失败' + result)
        }
      })

      function drawRoute(route) {
        var path = parseRouteToPath(route)

        var startMarker = new AMap.Marker({
          position: path[0],
          icon: new AMap.Icon({
            size: new AMap.Size(36, 36), // 图标尺寸
            image: require('../../assets/img/start.png'), // Icon的图像
            imageSize: new AMap.Size(36, 36) // 根据所设置的大小拉伸或压缩图片
          }),
          map: map
        })
        console.log(path, 'path')
        var endMarker = new AMap.Marker({
          position: path[path.length - 1],
          icon: new AMap.Icon({
            size: new AMap.Size(36, 36), // 图标尺寸
            image: require('../../assets/img/end.png'), // Icon的图像
            imageSize: new AMap.Size(36, 36) // 根据所设置的大小拉伸或压缩图片
          }),
          map: map
        })

        var routeLine = new AMap.Polyline({
          path: path,
          isOutline: true,
          strokeColor: '#0077ff', //线颜色
          strokeOpacity: 1, //线透明度
          strokeWeight: 8, //线宽
          outlineColor: '#e6f1ff',
          borderWeight: 4,
          strokeStyle: 'solid', //线样式
          showDir: true //是否显示箭头
        })

        routeLine.setMap(map)

        // 调整视野达到最佳显示区域
        map.setFitView([startMarker, endMarker, routeLine])
      }

      // 解析WalkRoute对象，构造成AMap.Polyline的path参数需要的格式
      // WalkRoute对象的结构文档 https://lbs.amap.com/api/javascript-api/reference/route-search#m_WalkRoute
      function parseRouteToPath(route) {
        var path = []

        for (var i = 0, l = route.steps.length; i < l; i++) {
          var step = route.steps[i]

          for (var j = 0, n = step.path.length; j < n; j++) {
            path.push(step.path[j])
          }
        }

        return path
      }
    },
    initMap() {
      var map = new AMap.Map('Map', {
        resizeEnable: true,
        center: [116.397428, 39.90923], //地图中心点
        zoom: 13 //地图显示的缩放级别
      })
    },
    go(road1, road2) {
      var that = this
      // 当前示例的目标是展示如何根据规划结果绘制路线，因此walkOption为空对象
      var walkingOption = {}

      // 步行导航
      var walking = new AMap.Walking(walkingOption)

      //根据起终点坐标规划步行路线
      walking.search([116.399028, 39.845042], [116.436281, 39.880719], function(status, result) {
        // result即是对应的步行路线数据信息，相关数据结构文档请参考  https://lbs.amap.com/api/javascript-api/reference/route-search#m_WalkingResult
        if (status === 'complete') {
          if (result.routes && result.routes.length) {
            drawRoute(result.routes[0])
            log.success('绘制步行路线完成')
          }
        } else {
          log.error('步行路线数据查询失败' + result)
        }
      })

      function drawRoute(route) {
        var path = parseRouteToPath(route)

        var startMarker = new AMap.Marker({
          position: path[0],
          icon: 'https://webapi.amap.com/theme/v1.3/markers/n/start.png',
          map: that.map,
          anchor: 'bottom-center'
        })

        var endMarker = new AMap.Marker({
          position: path[path.length - 1],
          icon: 'https://webapi.amap.com/theme/v1.3/markers/n/end.png',
          map: that.map,
          anchor: 'bottom-center'
        })

        var routeLine = new AMap.Polyline({
          path: path,
          isOutline: true,
          outlineColor: '#ffeeee',
          borderWeight: 2,
          strokeWeight: 5,
          strokeColor: '#0091ff',
          strokeOpacity: 0.9,
          lineJoin: 'round'
        })

        that.map.add(routeLine)

        // 调整视野达到最佳显示区域
        that.map.setFitView([startMarker, endMarker, routeLine])
      }

      // 解析WalkRoute对象，构造成AMap.Polyline的path参数需要的格式
      // WalkRoute对象的结构文档 https://lbs.amap.com/api/javascript-api/reference/route-search#m_WalkRoute
      function parseRouteToPath(route) {
        var path = []

        for (var i = 0, l = route.steps.length; i < l; i++) {
          var step = route.steps[i]

          for (var j = 0, n = step.path.length; j < n; j++) {
            path.push(step.path[j])
          }
        }

        return path
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.map-detail,
#Map {
  width: 100%;
  height: 100%;
}
#panel {
  position: fixed;
  background-color: white;
  max-height: 90%;
  overflow-y: auto;
  top: 10px;
  right: 10px;
  width: 280px;
}
#panel .amap-call {
  background-color: #009cf9;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
}
#panel .amap-lib-walking {
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  overflow: hidden;
}
</style>
