import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";
import MapDet from "../views/Map/mapDetail.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    meta: {
      title: "上海市奉贤区博物馆",
    },
    component: Home,
  },
  {
    path: "/mapDet",
    name: "MapDet",
    meta: {
      title: "上海市奉贤区博物馆",
    },
    component: MapDet,
  },
];

const router = new VueRouter({
  mode: "hash",
  base: process.env.BASE_URL,
  routes,
});
router.beforeEach((to, from, next) => {
  if (to.meta.title) {
    document.title = to.meta.title;
  } else {
    document.title = "上海市奉贤区博物馆";
  }
  next();
});
export default router;
