<template>
  <div class="home">
    <div class="map-item">
      <Map />
    </div>
  </div>
</template>

<script>
import Map from "./Map";
export default {
  name: "Home",
  data() {
    return {
      value: "",
    };
  },
  components: {
    Map,
  },
};
</script>

<style lang="scss" scoped>
.home {
  display: flex;
  flex-direction: column;
}
.map-item {
  flex: 1;
}
</style>