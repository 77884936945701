import axios from 'axios'
import Nprogress from 'nprogress'
import 'nprogress/nprogress.css'
// export const baseURL = 'http://smartsite.api.zhiqiankeji.com:8828/back'
export const baseURL = 'https://hyexp.zhiqiankeji.com/back'

const http = axios.create({
  timeout: 2000,
  baseURL
})

http.interceptors.request.use(
  config => {
    Nprogress.start()

    return config
  },
  error => {
    console.log(error)
  }
)

http.interceptors.response.use(
  response => {
    Nprogress.done()
    return response
  },
  error => {
    console.log(error)
  }
)

export { http }
